import './main.css';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Status from './Status';
import { ReactComponent as Info } from '../assets/info.svg';
import NoSubs from './NoSubs';
import MailTo from './ButtonMailto';
import { ReactComponent as Back } from '../assets/back-button.svg';
import MessageModal from './Modal';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from './Pagination';
import Spinner from './Spinner';
import { productMap } from '../utils/productSKU';
import { fetchSubscriptionsByProductCodes } from '../store/actions/subscriptionActions';

const DATAWAREHOUSE_BASE_URL = process.env.REACT_APP_DATAWAREHOUSE_URL;

const SubscriptionInfoLayout = ({ navigate, sku }) => {
  const fetchedSKUs = useSelector((state) => state.sku.fetchedSKUs);
  const { currentPage, itemsPerPage } = useSelector(
    (state) => state.pagination
  );
  const [modalStates, setModalStates] = useState({});
  const allSubscriptions = useSelector(
    (state) => state.subscription.subscriptions
  );
  const subscriptionLoading = useSelector(
    (state) => state.subscription.loading
  );
  const loading = subscriptionLoading;
  const dispatch = useDispatch();

  const handleSubscription = (item) => {
    navigate(`/account/my-app-information-item/subscription/${item.id}`);
  };

  const handleSyncDateClick = (subscriptionId) => {
    setModalStates((prevModalStates) => ({
      ...prevModalStates,
      [subscriptionId]: true,
    }));
  };

  useEffect(() => {
    if (!fetchedSKUs.includes(sku)) {
      dispatch(fetchSubscriptionsByProductCodes(sku));
    }
  }, [sku, dispatch, fetchedSKUs]);

  if (loading) {
    return <Spinner label="Loading..." />;
  }

  const subscriptions = allSubscriptions.filter((subscription) =>
    sku !== 'HSBYOD'
      ? subscription.productSKU === sku
      : subscription.productCode === sku
  );

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = subscriptions.slice(firstItemIndex, lastItemIndex);

  return (
    <div className="app-container">
      <div>
        {subscriptions && subscriptions.length ? (
          <>
            <Back
              style={{
                marginBottom: '1em',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch({ type: 'RESET_PAGINATION' });
                navigate('/account/my-app-information-item/')
              }}
            />
            <div className="mainheading">
              <div className="headingtitle">My App Information</div>
              <div className="subtitle">
                Select an App Subscription below to view your connector details.
              </div>
            </div>

            <div className="headers">
              <div style={{ textAlign: 'center' }}>App Name</div>
              <div>Last sync time</div>
              <div>Portal ID</div>
              <div>Subscription #</div>
              <div>Status</div>
            </div>
            {currentItems.map((item) => {
              return (
                <div
                  className="subs"
                  key={item.id}
                  onClick={() => handleSubscription(item)}
                >
                  <div className="gridmain1">
                    {' '}
                    {
                      productMap.find(
                        (product) =>
                          product.sku === item.productSKU ||
                          product.sku === item.productCode
                      ).name
                    }
                  </div>
                  <div
                    className="gridmain2"
                    style={{ fontWeight: 'var(--fw-bold)' }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        color: '#E8662E',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSyncDateClick(item.id); // Pass the subscription ID
                      }}
                    >
                      {item.lastSyncDate &&
                        moment(item.lastSyncDate)
                          .tz('America/New_York')
                          .format('MM/DD/YY')}
                    </div>
                    <MessageModal
                      isOpen={modalStates[item.id]} // Use the corresponding modal state
                      setIsOpen={(isOpen) =>
                        setModalStates((prevModalStates) => ({
                          ...prevModalStates,
                          [item.id]: isOpen,
                        }))
                      }
                      setIsClose={() =>
                        setModalStates((prevModalStates) => ({
                          ...prevModalStates,
                          [item.id]: null,
                        }))
                      }
                      title="Last Sync Time"
                      message={[
                        {
                          title: item.productSKU,
                          value: moment(item.lastSyncDate)
                            .tz('America/New_York')
                            .format('MM/DD/YY hh:mm:ss A'),
                        },
                      ]}
                    />
                  </div>
                  <div className="gridmain3">{item.portalId}</div>
                  <div className="gridmain4">
                    <a
                      href={`${DATAWAREHOUSE_BASE_URL}/account/view-subscription/${item.subscriptionWordpressId}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.subscriptionWordpressId}
                    </a>
                  </div>
                  <div className="gridmain5" style={{ fontStyle: 'italic' }}>
                    <Status status={item.status} />
                  </div>
                </div>
              );
            })}
            {subscriptions.length > itemsPerPage && (
              <Pagination totalItems={subscriptions.length} />
            )}
            <div className="contactframe">
              <Info /> Can't find what you are looking for?{' '}
              <span>
                <MailTo weight="regular" size="12" />
              </span>
            </div>
          </>
        ) : (
          <NoSubs />
        )}
      </div>
    </div>
  );
};

export default SubscriptionInfoLayout;
