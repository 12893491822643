const initialState = {
  currentPage: 1,
  itemsPerPage: 20,
};

export const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'RESET_PAGINATION':
      return {
        ...state,
        currentPage: 1,
      };
    default:
      return state;
  }
};
