const initialState = {
  hsmOrders: null,
  portalToolkitData: null,
  schemaReplicatorData: null,
  portalSyncData: null,
  loading: false,
  error: [],
  hasInitialized: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_HSM_DATA_START':
    case 'FETCH_SCHEMA_REPLICATOR_START':
    case 'FETCH_PORTAL_TOOLKIT_START':
    case 'FETCH_PORTAL_SYNC_START':
      return {
        ...state,
        loading: true,
        error: [...state.error],
        hasInitialized: true,
      };

    case 'FETCH_HSM_DATA_SUCCESS': {
      let userMap =
        state.hsmOrders?.orders?.reduce((map, user) => {
          map[user.id] = user;
          return map;
        }, {}) || {};

      action.payload.orders.forEach((user) => {
        userMap[user.id] = { ...userMap[user.id], ...user };
      });

      const mergedUsers = Object.values(userMap);

      console.log(mergedUsers, 'mergedUsers');

      return {
        ...state,
        loading: false,
        hsmOrders: { ...state.hsmOrders, orders: mergedUsers },
        hasInitialized: true,
      };
    }

    case 'FETCH_PORTAL_TOOLKIT_SUCCESS':
      return {
        ...state,
        loading: false,
        portalToolkitData: action.payload,
        hasInitialized: true,
      };

    case 'FETCH_SCHEMA_REPLICATOR_SUCCESS':
      return {
        ...state,
        loading: false,
        schemaReplicatorData: action.payload,
        hasInitialized: true,
      };

    case 'FETCH_PORTAL_SYNC_SUCCESS':
      return {
        ...state,
        loading: false,
        portalSyncData: action.payload,
        hasInitialized: true,
      };

    case 'UPDATE_ORDER_STATUS':
      return {
        ...state,
        hsmOrders: state.hsmOrders
          ? {
              ...state.hsmOrders,
              orders: state.hsmOrders.orders.map((order) =>
                order.id === action.payload.orderId
                  ? { ...order, status: action.payload.newStatus }
                  : order
              ),
            }
          : null,
        portalToolkitData: state.portalToolkitData
          ? {
              ...state.portalToolkitData,
              operations: state.portalToolkitData.operations.map((operation) =>
                operation.id === action.payload.orderId
                  ? { ...operation, status: action.payload.newStatus }
                  : operation
              ),
            }
          : null,
        schemaReplicatorData: state.schemaReplicatorData
          ? {
              ...state.schemaReplicatorData,
              operations: state.schemaReplicatorData.operations.map(
                (operation) =>
                  operation.id === action.payload.orderId
                    ? { ...operation, status: action.payload.newStatus }
                    : operation
              ),
            }
          : null,
        portalSyncData: state.portalSyncData
          ? {
              ...state.portalSyncData,
              operations: state.portalSyncData.operations.map((operation) =>
                operation.id === action.payload.orderId
                  ? { ...operation, status: action.payload.newStatus }
                  : operation
              ),
            }
          : null,
      };

    case 'NO_HSMTLKIT_SUBSCRIPTION_FOUND':
      return {
        ...state,
        loading: false,
        portalToolkitData: { subscription: null },
        hasInitialized: true,
      };

    case 'NO_HSMSCH_SUBSCRIPTION_FOUND':
      return {
        ...state,
        loading: false,
        schemaReplicatorData: { subscription: null },
        hasInitialized: true,
      };

    case 'NO_HSMPS_SUBSCRIPTION_FOUND':
      return {
        ...state,
        loading: false,
        portalSyncData: { subscription: null },
        hasInitialized: true,
      };

    case 'FETCH_HSM_DATA_NOT_FOUND': {
      return {
        ...state,
        loading: false,
        hsmOrders: { ...state.hsmOrders, [action.payload]: { isEmpty: true } },
        hasInitialized: true,
      };
    }

    case 'FETCH_HSM_DATA_FAILURE':
      return {
        ...state,
        loading: false,
        error: [...state.error, action.payload],
        hsmOrders: { ...state.hsmOrders, [action.payload]: { error: true } },
        hasInitialized: true,
      };

    case 'FETCH_SCHEMA_REPLICATOR_FAILURE':
    case 'FETCH_PORTAL_TOOLKIT_FAILURE':
    case 'FETCH_PORTAL_SYNC_FAILURE':
      return {
        ...state,
        loading: false,
        error: [...state.error, action.payload],
        hasInitialized: true,
      };

    default:
      return state;
  }
};
